<template>
  <div>
    <b-form >
      <b-row>
        <b-col md="6" class="mb-1" v-if="isCashier || isAdmin ">
          <b-form-input
            v-model="filter.search"
            placeholder="Correo electrónico o Teléfono"
            @input="filterabled"
          />
        </b-col>
        <b-col md="6" class="mb-1 " v-if="isCashier || isAdmin ">
          <v-select
            class="mx-select"
            v-model="filter.school_id"
            :options="optionSchools"
            :reduce="(item) => item.id"
            placeholder="Colegios"
            label="name"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="mb-1">
          <v-select
            v-model="filter.payment_type_id"
            :options="optionsPaymentTypes"
            :reduce="(item) => item.id"
            placeholder="Tipo de operación"
            label="name"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="mb-1">
          <v-select
            v-model="filter.payment_method_id"
            :options="optionsPaymentMethods"
            :reduce="(item) => item.code"
            multiple
            placeholder="Método de pago"
            label="name"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="mb-1">
          <v-select
            v-model="filter.status"
            :options="optionStatus"
            :reduce="(item) => item.id"
            placeholder="Estado"
            label="name"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="mb-1" v-if="isCashier || isAdmin ">
          <b-form-input
            v-model="filter.amount"
            type="number"
            placeholder="Monto"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="mb-1">
          <date-picker
            v-model="filter.date"
            range
            type="date"
            valueType="format"
            format="DD/MM/YYYY"
            :default-value="new Date()"
            :disabled-date="disabledBeforeToday"
            placeholder="Fecha"
            @input="filterabled"
          />
        </b-col>
        <b-col md="4" class="d-flex justify-content-end items-end mb-1">
          <b-button variant="primary" @click="restareFilter" class="ml-1">
            Reiniciar
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-card class="mb-2" v-for="(item, i) in items.data" :key="i">
      <b-overlay
        :show="loading"
        rounded
        responsive
        class="table-responsive"
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <b-row class="d-flex align-items-center px-2">
          <b-col>
            <p class="text-nowrap">{{ item.school.name }}</p>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center px-2">
          <b-col>
            <p class="text-nowrap">
              {{ `${item.user.name} ${item.user.last_name}` }}
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Operación </b-col>
          <b-col>
            <p class="text-nowrap" v-if="!isAdmin || !isCashier">
              {{ item.type == "Venta" ? "Compra" : item.type }}
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Movimiento </b-col>
          <b-col>
            <b-link
              v-if="item.invoice_id"
              :to="{
                name: 'apps-invoice-preview',
                params: { id: item.invoice_id },
              }"
              class="font-weight-bold d-block"
            >
              <p class="text-nowrap">Factura #{{ item.invoice_id }}</p>
            </b-link>
            <b-link
              v-else
              :to="{ name: 'profile-user', params: { id: item.user.id } }"
              class="font-weight-bold d-block"
            >
              <p class="text-nowrap">
                {{ `${item.user.name} ${item.user.last_name}` }}
              </p>
            </b-link>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Estatus </b-col>
          <b-col>
            <b-badge
              pill
              :variant="`light-${statusGetClass(item.status)}`"
              class="text-capitalize"
            >
              {{ item.status }}
            </b-badge>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Conexión </b-col>
          <b-col>
            <span v-if="item.from == '' || item.from == 'APP'"> APP </span>
            <span v-else-if="item.from == 'ATM'"> ATM </span>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Saldo Anterior </b-col>
          <b-col>
            <p class="text-nowrap" v-if="item.previous_balance">
              {{ item.previous_balance | currency({ symbol: "$", precision: 2 }) }}
            </p>
            <p class="text-nowrap" v-else>-</p>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Monto </b-col>
          <b-col>
            <p
              class="text-nowrap"
              :class="item.type == 'Venta' ? 'text-primary' : 'text-success'"
            >
              {{ item.type == "Venta" ? "-" : "" }}

              {{ item.amount | currency({ symbol: "$", precision: 2 }) }}
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Saldo Actual </b-col>
          <b-col>
            <p class="text-nowrap" v-if="item.previous_balance">
              {{ resultBalance(item) | currency({ symbol: "$", precision: 2 }) }}
            </p>
            <p v-else class="text-nowrap">-</p>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2">
          <b-col> Pago </b-col>
          <b-col>
            <b-badge
              v-for="item in item.payments"
              :key="item.id"
              pill
              :variant="`success`"
              class="text-capitalize"
            >
              {{ item.paymentMethod }} ${{ item.amount }}
            </b-badge>
          </b-col>
        </b-row>
        <hr />
        <b-row class="d-flex align-items-center px-2 mb-1">
          <b-col> Fecha </b-col>
          <b-col>
            <p class="text-nowrap">
              {{ item.created_at | formatDateTimeHumane }}
            </p>
          </b-col>
        </b-row>
        <div v-if="item.comment">
          <hr />
          <b-row class="d-flex align-items-center px-2">
            <b-col> Comentario </b-col>
            <b-col>
              <p class="text-nowrap">{{ item.comment }}</p>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            first-number
            @change="getItems"
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BBadge,
  BPagination,
  BOverlay,
  BFormGroup,
  BButton,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    BFormGroup,
    BButton,
    BFormInput,
    BForm,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      items: {},
      filter: {
        email: null,
        date: [moment().subtract(1, 'month').format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
        school_id: null,
        payment_type_id: null,
        payment_method_id: null,
        status: null,
        amount: null,
      },
      rejectOptions: [],
      optionSchools: [],
      optionsPaymentTypes: [],
      optionsPaymentMethods: [],
      optionStatus: [],
    };
  },
  filters: {
    formatDateTimeHumane,
  },
  mounted() {
    this.getItems();
    this.components();
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
  },
  methods: {
    async getItems(page = 1) {
      this.currentPage = page;

      if (
        !this.filter.date ||
        this.filter.date.filter((item) => item == null).length > 0
      ) {
        delete this.filter.date;
      }
      try {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
          this.loading = true;
          this.items = await this.$store.dispatch("transactions/index", {
            ...this.filter,
            ...this.$route.query,
            page: this.currentPage,
          });
          this.loading = false;
        }, 500); // 500ms de retraso
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    resultBalance(data) {
      const type = data.type;

      let amount =
        data.payments?.reduce((acc, payment) => {
          if (payment.paymentMethod == "WALLET" || payment.paymentMethod == "CREDIT") {
            return acc + parseFloat(payment.amount);
          }
          return acc;
        }, 0) || 0;

      amount = type == "Venta" ? amount : data.amount;

      const previous_balance = data.previous_balance;

      let total;
      if (!amount || !previous_balance) {
        return;
      }

      if (type == "Recarga") {
        total = parseFloat(previous_balance) + parseFloat(amount);
        return total.toFixed(2);
      } else if (type == "Venta") {
        total = parseFloat(previous_balance) - parseFloat(amount);
        return total.toFixed(2);
      }
    },
    async filterabled() {
      this.getItems(this.currentPage);
    },

    restareFilter() {
      try {
        this.loading = true;
        this.filter = {
          email: null,
          date: [moment().subtract(1, 'month').format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
          school_id: null,
          payment_type_id: null,
          payment_method_id: null,
          status: null,
        };
        let query = Object.assign({}, this.$route.query);
        delete query.userId;
        this.$router.replace({ query });
        this.getItems();
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async components() {
      await this.$store.dispatch("app/resources", { transactions: true }).then((res) => {
        this.optionSchools = res.schools;
        this.optionsPaymentTypes = res.payment_types;
        this.optionsPaymentMethods = res.payment_methods;
        this.optionStatus = res.status;
        this.rejectOptions = res.rejectOptions;
      });
    },
    disabledBeforeToday(date) {
      const today = new Date();

      return date >= new Date(today.getTime());
    },
    statusGetClass(status) {
      switch (status) {
        case "Pendiente":
          return "warning";
        case "Aprobado":
          return "success";
        case "Rechazado":
          return "danger";
        default:
          return "secondary";
      }
    },
  },
};
</script>

<style lang="scss">
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
